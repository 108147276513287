/* SignUp.css */
.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signup-form input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}
.signup-form{
  align-items: center;
  display: flex;
  flex-direction: column;
}


.signup-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signup-form button:hover {
  background-color: #0056b3;
}
