.tarea-texto{
    width:100%;
    height: 100%;
    font-size: 20px;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
  }
  .oculto{
    display:none;
  }
  .contenedor-lista-tareas{
    width: 500px;
  }