.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .login-form input {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .login-button {
    background-color: #2196f3;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
  }